import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../api';
import {DEFAULT_IMAGE_URI} from '../../../api/constants';
import db from '../../../api/firebase.config';
import {_decodeData} from '../../../api/helpers';
import {reset} from "../../login/components/loginSlice";
const roomsRef  =  db.collection('rooms');


export const getInboxRoom = createAsyncThunk(
  'messageList/getInboxRoom', // function name to call in login screen
  async ({user,contactUser,token}, thunkAPI) => {
    try {
        const _roomName = await roomName(contactUser.contactUserId,user.LoggedInId);
        const checkRoom = await roomsRef.doc(_roomName).get();
        if (checkRoom.exists) {
             return {
                apiRet:  {
                    roomName:checkRoom.id,
                    contactName:contactUser.contactUserName
                } // _roomName
            };
        }else{
            let keywords = [{name: contactUser.contactUserName,roomName : _roomName},{ name: user.LoggedInName,roomName : _roomName}]
            const body = JSON.stringify({
                keywords: keywords,
            });
            const contacts  = await api.post(`contacts/`, 'saveInboxKeyboards', body, token);
            let contactdata = await contacts.json();
            if (contacts.status === 200) {
                let _data = await _decodeData(contactdata);
                if (_data.status) {
                    // console.log('await saveRoom(user,contactUser, _roomName)',await saveRoom(user,contactUser, _roomName));
                    return {
                        apiRet:  {
                            roomName:await saveRoom(user,contactUser, _roomName),
                            contactName:contactUser.contactUserName
                        } // _roomName
                    };
                }else{
                    return thunkAPI.dispatch(reset({}));
                }
            }else{
                  return thunkAPI.dispatch(reset({}));
            }
        }

    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: {errors:[]},
      });
    }
  },
);


// Slice
const InboxMessageListSlice = createSlice({
  name: 'inboxmessageList',
  initialState : {
      list       : [],
      isFetching : false,
      isError    : false,
      roomName   : null,
      contactName: null,
      selected   : null
  },
  reducers: {
    assignValue: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {

    [getInboxRoom.fulfilled]: (state, {payload}) => {
        // console.log('payload=====',payload);
      state.isError    = false;
      state.roomName   = payload.apiRet.roomName;
      state.contactName   = payload.apiRet.contactName;
      state.isFetching = false;
    },
    [getInboxRoom.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError    = true;
    },
    [getInboxRoom.pending]: (state) => {
      state.isFetching = true;
      state.isError    = false;
    },

  },
});

export const {assignValue} = InboxMessageListSlice.actions;
export default InboxMessageListSlice.reducer;


const roomName = (contactPersonID,LoggedInId) => {
    var arr = [contactPersonID.toString(), LoggedInId.toString()];
    arr.sort(function (a, b) {
        return a.localeCompare(b);
    });
    arr = 'room_' + (arr.join("_"));
    return arr;
}


const saveRoom = async (user,contactUser,_roomName)  => {
    return new Promise((resolve) => {
        let img = [
            {
                avatar: contactUser.contactUserImage !== null ? contactUser.contactUserImage : DEFAULT_IMAGE_URI,
                id: contactUser.contactUserId,
            },
            {
                avatar: user.LoggedInImage !== null ? user.LoggedInImage : DEFAULT_IMAGE_URI,
                id: user.LoggedInId,
            },
        ]

        let name = [
            {
                name: contactUser.contactUserName,
                id: contactUser.contactUserId,
            },
            {
                name: user.LoggedInName,
                id: user.LoggedInId,
            },
        ]

        let typingstate = [
            {
                id: contactUser.contactUserId,
                typing: false,
            },
            {
                id: user.LoggedInId,
                typing: false,
            },
        ]

        let readstate = [
            {
                id: contactUser.contactUserId,
                read: false,
            },
            {
                id: user.LoggedInId,
                read: false,
            },
        ]

        roomsRef.doc(_roomName).set({
            createdAt: "",
            lastChat: "",
            userids: [contactUser.contactUserId, user.LoggedInId],
            name: name,
            typingstate: typingstate,
            avatar: img,
            readstate: readstate
        })
            .then((response) => {
                resolve(_roomName);
            })
            .catch((err) => {
                resolve(undefined);
            });
    });
}