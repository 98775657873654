import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

// const toasts={

// }


const initState ={
    Modal        : {
        modalTitle :null, 
        content    :null,
        size       :'lg',
        modal      :false,
        setModal   : null
    },
    Toaster      : {
      toaster : false,
      title   : '',
      msg     : '',
      bgColor : '',
      autohide: true ,
      autohideValue :3000,
      position : "top-right" 
    },
    AlertWarning : '',
}


// Slice
const CommonSlice = createSlice({
  name: 'common',
  initialState: initState,
  reducers: {
    assignState: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
    resetToaster: (state) => {
      state['Toaster'] = initState.Toaster;
    },
    reset: state => initState,
    reset2: state => initState,
  },
  extraReducers: {

  },
});

export const { assignState,reset,resetToaster,reset2} = CommonSlice.actions;
export default CommonSlice.reducer;
