import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../api';

export const loginUser = createAsyncThunk(
  'login/loginUser', // function name to call in login screen
  async ({body}, thunkAPI) => {
    try {
      const response = await api.auth('admin/','login', body);
      console.log('response',response);
      let data = await response.json();
      console.log('data',data);
      if (response.status === 200) {
        return {
          status: true,
          msg: 'Login Success',
          apiRet: data,
        };
      } else {
        return thunkAPI.rejectWithValue({
          status: false,
          msg: 'Login failed',
          apiRet: data,
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: 'Login failed',
        apiRet: {errors:[]},
      });
    }
  },
);

const initState ={
  is_loggedin: false,
  logindata: [],
  isFetching: false,
  isError: false,
  errorMessage: [],
  isWelcomed: false,
  return: null,
}


// Slice
const loginSlice = createSlice({
  name: 'login',
  initialState: initState,
  reducers: {
    logoutSuccess: (state, action) => {
      state.is_loggedin = false;
      state.isError     = false;
      state.isWelcomed  = false;
      state.logindata   = [];
    },
    assignState: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
    reset: state => initState
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, {payload}) => {
      state.is_loggedin = true;
      state.isError = false;
      state.logindata = payload.apiRet;
      state.isFetching = false;
      state.return = null;
    },
    [loginUser.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.return = payload.apiRet;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.return = null;
    },
  },
});

export const {logoutSuccess, setIsError, assignState,reset} = loginSlice.actions;
export default loginSlice.reducer;
