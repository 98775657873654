import React from 'react';
import { Route, Switch, BrowserRouter, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import { connect } from 'react-redux';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./modules/login/views'));
const ForgotPass = React.lazy(() => import('./modules/login/views/ForgotPass'));
const NewPass = React.lazy(() => import('./modules/login/views/NewPass'));
// const Chat = React.lazy(() => import('./modules/chat/Chat'));
const Page404 = React.lazy(() => import('./containers/page404/Page404'));
const Privacy = React.lazy(() => import('./containers/privacy/PrivacyPolicy'));
const Marketing = React.lazy(() => import('./containers/marketing/Marketing'));
const Support = React.lazy(() => import('./containers/support/Support'));
const Copyright = React.lazy(() => import('./containers/copyright/Copyright'));

const App = (props) => {
  const { is_loggedin } = props.login;
  return (
    // <BrowserRouter basename="/">
    <HashRouter >
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <Route  path="/contacts" exact name="Chat"     render={props => <Chat {...props}/>} /> */}
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/email" name="Forgot Password" render={props => <ForgotPass {...props} />} />
          <Route exact path="/newpass" name="New Password" render={props => <NewPass {...props} />} />

          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/privacy-policy" name="Privay Policy" render={props => <Privacy {...props} />} />
          <Route exact path="/marketing" name="Marekting" render={props => <Marketing {...props} />} />
          <Route exact path="/support" name="Support" render={props => <Support {...props} />} />
          <Route exact path="/copyright" name="Copyright" render={props => <Copyright {...props} />} />

          <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
    // </BrowserRouter>
  );
}


const getState = (state) => {
  return {
    login: state.login
  }
}

export default connect(getState, null)(App);
