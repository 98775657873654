import firebase from 'firebase'
var firebaseConfig = {
  apiKey: "AIzaSyA067DPbD-uEKCVFKAMAWIu_sAhN4aaWfI",
  authDomain: "sta-5a38f.firebaseapp.com",
  projectId: "sta-5a38f",
  storageBucket: "sta-5a38f.appspot.com",
  messagingSenderId: "656856222890",
  appId: "1:656856222890:web:76b734ca295f94576714d3",
  measurementId: "G-XKD91SMLL2"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;