import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api';
import { _decodeData } from '../../../api/helpers';
import { reset } from "../../login/components/loginSlice";

export const getUsers = createAsyncThunk(
  'trainers/getUsers', // function name to call in login screen
  async ({ params, token }, thunkAPI) => {
    try {
      const response = await api.get('users', '', token, params);
      let data = await response.json();
      if (response.status === 200) {
        const _data = await _decodeData(data);
        return {
          status: true,
          msg: ' Success',
          apiRet: {
            data: _data.data,
            total: _data.total
          }
        };
      } else {
        // call logout hhere
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: { errors: [] },
      });
    }
  },
);

export const getVenues = createAsyncThunk(
  'trainers/getVenues', // function name to call in login screen
  async ({ body, token }, thunkAPI) => {
    try {
      const response = await api.post('venue/', 'getVenues', body, token);
      let data = await response.json();

      if (response.status === 200) {
        return {
          status: true,
          msg: ' Success',
          apiRet: await _decodeData(data),
        };
      } else {
        // call logout hhere
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: { errors: [] },
      });
    }
  },
);

export const getOneUser = createAsyncThunk(
  'trainers/getOneUser', // function name to call in login screen
  async ({ body, token }, thunkAPI) => {
    try {
      const response = await api.post('users/', 'getUserDetails', body, token);
      let data = await response.json();
      if (response.status === 200) {
        return {
          status: true,
          msg: ' Success',
          apiRet: await _decodeData(data),
        };
      } else {
        // call logout hhere
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: { errors: [] },
      });
    }
  },
);

// Slice
const TrainerListsSlice = createSlice({
  name: 'trainers',
  initialState: {
    userslists: [],
    venuelists: [],
    oneUser: [],
    isFetching: false,
    isError: false,
  },
  reducers: {
    assignState: (state, action) => {
      const { slicekey, value } = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.userslists = payload.apiRet.data;
      state.total = payload.apiRet.total;
      state.isFetching = false;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getUsers.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },

    [getVenues.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.venuelists = payload.apiRet;
      state.isFetching = false;
    },
    [getVenues.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getVenues.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },

    [getOneUser.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.oneUser = payload.apiRet;
      state.isFetching = false;
    },
    [getOneUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getOneUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },

  },
});

export const { logoutSuccess, setIsError, assignState } = TrainerListsSlice.actions;
export default TrainerListsSlice.reducer;
