import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api';
import { _decodeData } from '../../../api/helpers';
import { reset } from "../../../modules/login/components/loginSlice";

export const getBackOrders = createAsyncThunk(
  'orders/getBackOrders', // function name to call in login screen
  async ({ params, token }, thunkAPI) => {
    try {
      const response = await api.get('orders', '', token, params);
      let data = await response.json();
      if (response.status === 200) {
        const _data = await _decodeData(data);
        return {
          status: true,
          msg: ' Success',
          apiRet: {
            data: _data.data,
            total: _data.total
          }
        };
      } else {
        // call logout hhere
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: { errors: [] },
      });
    }
  },
);


// Slice
const OrderListSlice = createSlice({
  name: 'backorders',
  initialState: {
    orderslists: [],
    venuelists: [],
    oneUser: [],
    isFetching: false,
    isError: false,
  },
  reducers: {
    assignState: (state, action) => {
      const { slicekey, value } = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {
    [getBackOrders.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.orderslists = payload.apiRet.data;
      state.total = payload.apiRet.total;
      state.isFetching = false;
    },
    [getBackOrders.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getBackOrders.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
  },
});

export const { logoutSuccess, setIsError, assignState } = OrderListSlice.actions;
export default OrderListSlice.reducer;
