import { site_url } from './constants';

export const api = {
    auth: (mod, action, params) => {
        return fetch(`${site_url + mod + action}`, {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authstr': '',
            },
            body: params
        });
    },
    put: (mod, action, video, headers) => {
        const bodyFormData = new FormData();
        bodyFormData.append("media", {
            name: video.filename,
            type: video.type,
            uri: video.path
        });

        return fetch(`${site_url + mod + action}`, {
            method: 'PUT',
            headers: headers,
            body: bodyFormData
        });
    },
    post: (mod, action, params = '', token = '') => {
        return fetch(`${site_url + mod + action}`, {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authstr': token,
            },
            body: params
        });
    },
    patch: (mod, action, params = '', token = '') => {
        return fetch(`${site_url + mod + action}`, {
            method: 'PATCH',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authstr': token,
            },
            body: params
        });
    },
    get: (mod, action = "", token = '', params = null) => {
        let url = `${site_url + mod}`;

        if (action) {
            url = url + "/" + action;
        }

        if (params) {
            url = url + "?" + new URLSearchParams(params).toString()
        }

        return fetch(url, {
            method: 'GET',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'authstr': token,
            }
        });
    },
    upload: (mod, action, body = {}, token = '') => {
        return fetch(`${site_url + mod + action}`, {
            method: 'POST',
            body: body,
            headers: {
                'authstr': token,
            },
        });
    },
}

