import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api';
import { DEFAULT_IMAGE_URI } from '../../../api/constants';
import db from '../../../api/firebase.config';
import { _decodeData } from '../../../api/helpers';
import { reset } from "../../login/components/loginSlice";
const roomsRef = db.collection('rooms');

// Slice
const ConfirmationSlice = createSlice({
    name: 'confirmgrades',
    initialState: {
        list: [],
        isFetching: false,
        isError: false,
        roomName: null,
        contactName: null,
    },
    reducers: {
        assignState2: (state, action) => {
            const { slicekey, value } = action.payload;
            state[slicekey] = value;
        },
    },
    extraReducers: {

    },
});

export const { assignState2 } = ConfirmationSlice.actions;
export default ConfirmationSlice.reducer;




export const getRoomForGrading = async ({ user, contactUser, token }) => {
    const _roomName = await roomName(contactUser.contactUserId, user.LoggedInId);
    const checkRoom = await roomsRef.doc(_roomName).get();
    if (checkRoom.exists) {
        return {
            roomName: checkRoom.id,
            contactName: contactUser.contactUserName,
            status: true
        }
    } else {
        let keywords = [{ name: contactUser.contactUserName, roomName: _roomName }, { name: user.LoggedInName, roomName: _roomName }]
        const body = JSON.stringify({
            keywords: keywords,
        });
        const contacts = await api.post(`contacts/`, 'saveInboxKeyboards', body, token);
        let contactdata = await contacts.json();
        if (contacts.status === 200) {
            let _data = await _decodeData(contactdata);
            if (_data.status) {
                return {
                    roomName: await saveRoom(user, contactUser, _roomName),
                    contactName: contactUser.contactUserName,
                    status: true
                }
            } else {
                return {
                    status: false
                }
            }
        } else {
            return {
                status: false
            }
        }
    }
}



const roomName = (contactPersonID, LoggedInId) => {
    var arr = [contactPersonID.toString(), LoggedInId.toString()];
    arr.sort(function (a, b) {
        return a.localeCompare(b);
    });
    arr = 'room_' + (arr.join("_"));
    return arr;
}


const saveRoom = async (user, contactUser, _roomName) => {
    return new Promise((resolve) => {
        let img = [
            {
                avatar: contactUser.contactUserImage !== null ? contactUser.contactUserImage : DEFAULT_IMAGE_URI,
                id: contactUser.contactUserId,
            },
            {
                avatar: user.LoggedInImage !== null ? user.LoggedInImage : DEFAULT_IMAGE_URI,
                id: user.LoggedInId,
            },
        ]

        let name = [
            {
                name: contactUser.contactUserName,
                id: contactUser.contactUserId,
            },
            {
                name: user.LoggedInName,
                id: user.LoggedInId,
            },
        ]

        let typingstate = [
            {
                id: contactUser.contactUserId,
                typing: false,
            },
            {
                id: user.LoggedInId,
                typing: false,
            },
        ]

        let readstate = [
            {
                id: contactUser.contactUserId,
                read: false,
            },
            {
                id: user.LoggedInId,
                read: false,
            },
        ]

        roomsRef.doc(_roomName).set({
            createdAt: "",
            lastChat: "",
            userids: [contactUser.contactUserId, user.LoggedInId,contactUser.contactUserId.toString(),user.LoggedInId.toString()],
            name: name,
            typingstate: typingstate,
            avatar: img,
            readstate: readstate
        })
            .then((response) => {
                resolve(_roomName);
            })
            .catch((err) => {
                resolve(undefined);
            });
    });
}
