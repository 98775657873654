import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../api';
import {_decodeData} from '../../../api/helpers';
import {reset} from "../../login/components/loginSlice";

export const getVenues = createAsyncThunk(
  'venueContacts/getVenues', // function name to call in login screen
  async ({body,token}, thunkAPI) => {
    try {
      const response = await  api.post('venue/','getVenues',body,token);
      let data = await response.json();
      if (response.status === 200) {
        return {
          status: true,
          msg: ' Success',
          apiRet: await _decodeData(data),
        };
      } else {
         // call logout hhere
         return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: {errors:[]},
      });
    }
  },
);


// Slice
const VenueListSlice = createSlice({
  name: 'venueContacts',
  initialState : {
      venues : [],
      isFetching : false,
      isError    : false,
      selected   : '',
  },
  reducers: {
    assignState: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {
    [getVenues.fulfilled]: (state, {payload}) => {
      state.isError    = false;
      state.venues     = payload.apiRet;
      state.isFetching = false;
    },
    [getVenues.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError    = true;
    },
    [getVenues.pending]: (state) => {
      state.isFetching = true;
      state.isError    = false;
    },
  },
});

export const {assignState} = VenueListSlice.actions;
export default VenueListSlice.reducer;
