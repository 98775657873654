import { combineReducers } from "redux";
import login from "./modules/login/components/loginSlice";
import users from "./modules/users/slices/UserListsSlice";
import trainers from "./modules/trainers/components/TrainerListsSlice";
import sidebar from "./containers/TheSidebarSlice";
import orders from "./modules/orders/components/OrderListSlice";
import backorders from "./modules/backorders/components/BackOrderListSlice";
import inventories from "./modules/inventory/components/InventorySlice";
import belts from "./modules/belts/components/BeltListSlice";
import venues from "./modules/venue/components/VenueListSlice";
import events from "./modules/events/components/EventListSlice";
import admins from "./modules/admin/components/AdminListSlice";
import bookings from "./modules/booking/components/BookingListSlice";

import venueContacts from "./modules/chat/venueList/VenueListSlice";
import contactList from "./modules/chat/contactList/ContactListSlice";
import messageList from "./modules/chat/messageList/MessageListSlice";
import inboxmessageList from "./modules/inbox/messages/InboxMessageListSlice";
import homeslice from "./modules/home/components/HomeSlice";
import attribute from "./modules/grading/slices/AttrSlice";

import common from "./common/CommonSlice";
import confirmgrades from "./modules/grading/slices/ConfirmationSlice";

export const rootReducer = combineReducers({
  login,
  users,
  trainers,
  sidebar,
  orders,
  inventories,
  belts,
  venues,
  events,
  admins,
  bookings,
  venueContacts,
  contactList,
  messageList,
  inboxmessageList,
  homeslice,
  common,
  attribute,
  confirmgrades,
  backorders,
});
