import Swal from "sweetalert2";
const jwt = require('jsonwebtoken');

export const isObject = (data) => {
    try {
        if (typeof data === 'object' && data !== null) {
            return true;
        } else {
            console.error("data must be an object");
        }
    } catch (e) {
        console.log(e);
    }
}

export const truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}

export const validateFields = (data) => {
    let err = [];
    const keys = Object.keys(data);
    keys.map((key) => {
        if (data[key] === "") {
            err.push(`${key} is required.`);
        }
    })

    return err;
}

export const formatAmount = (num) => {
    if (!num) return 0;
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}


export const _decodeData = (data) => {
    const { apimode, ret } = data;
    let retData;
    if (apimode === "LIVE") {
        retData = jwt.decode(ret);
    } else {
        retData = ret;
    }
    return retData;
}


export const showSwal = (props) => {
    const { method, ...restOfProps } = props;

    Swal.fire({
        ...restOfProps,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        onOpen: () => {
            // code
        }
    }).then((result) => {
        if (result.value) {
            props.method();
        }
    });
}

export const getBadge = (param) => {
    switch (param) {
        case "Yes": return 'dark'
        case "No": return 'secondary'
        case "0": return 'secondary'
        case "Active": return 'dark'
        case "Inactive": return 'secondary'
        case "Trainer": return 'dark'
        case "null": return 'secondary'
        case "Student": return 'dark'
        case "Guardian": return 'dark'
        case "Senior": return 'dark'
        case "Junior": return 'dark'
        case "PASS": return 'dark'
        case "FAIL": return 'secondary'
        case "Done": return 'secondary'
        case "No assigned": return 'secondary'
        case "ABSENT": return 'info'
        case "FOR REVIEW": return 'info'
        case "Exempted to next booking": return 'info'
        case "EXEMPTED": return 'info'
        default: return 'dark'
    }
}


export const _inputErrorMsgs = (param) => {
    if (param === null) {
        return;
    }
    return param.map((val, key) => {
        if (val.msg == undefined) {
            return <span key={key}>{val} <br /></span>;
        } else {
            return <span key={key}>{val.msg} <br /></span>;
        }
    });
}
