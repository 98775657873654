import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api';
import { _decodeData } from '../../../api/helpers';
import { reset } from "../../../modules/login/components/loginSlice";

export const getBelts = createAsyncThunk(
  'belts/getBelts', // function name to call in login screen
  async ({ params, token }, thunkAPI) => {
    try {
      const response = await api.get('belts', '', token, params);
      let data = await response.json();
      if (response.status === 200) {
        const _data = await _decodeData(data);
        return {
          status: true,
          msg: ' Success',
          apiRet: {
            data: _data.data,
            total: _data.total
          }
        };
      } else {
        // call logout hhere
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        status: false,
        msg: ' failed',
        apiRet: { errors: [] },
      });
    }
  },
);


// Slice
const BeltListSlice = createSlice({
  name: 'belts',
  initialState: {
    belts: [],
    isFetching: false,
    isError: false
  },
  reducers: {
    assignState: (state, action) => {
      const { slicekey, value } = action.payload;
      state[slicekey] = value;
    },
    // non async reducer
    ADD_STATE: (state, action) => {
      // "data" object key is required from your component method
      // adding of object to array state
      const data = action.payload.data
      state[action.payload.slicekey].push(data)
    },
    REMOVE_STATE: (state, action) => {
      // removing of states using array index or object value (unique id from DB)
      // if is_idx===true the where param is an array index else where is an  object value of array (unique id from DB)
      const { slicekey, where, objkey, is_idx, main_objkey } = action.payload
      if (is_idx) {
        state = state[slicekey].splice(where, 1);
      } else {
        state[slicekey].map((toRemove, idx) => {
          if (toRemove[objkey] === where) {
            state = state[slicekey].splice(idx, 1);
          }
        })
      }
    },
  },
  extraReducers: {
    [getBelts.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.belts = payload.apiRet.data;
      state.total = payload.apiRet.total;
      state.isFetching = false;
    },
    [getBelts.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getBelts.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
  },
});

export const { logoutSuccess, setIsError, assignState, ADD_STATE, REMOVE_STATE } = BeltListSlice.actions;
export default BeltListSlice.reducer;
