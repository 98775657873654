import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api';
import { _decodeData } from '../../../api/helpers';
import { reset } from "../../../modules/login/components/loginSlice";

export const getAttr = createAsyncThunk(
  'attribute/getAttr', // function name to call in login screen
  async ({ body,token }, thunkAPI) => {
    try {
      const response = await api.post(`grading/`,'getAttrs',body, token);
      let data = await response.json();
      if (response.status === 200) {
        return {
          apiRet: await _decodeData(data),
        };
      } else {
        return thunkAPI.dispatch(reset({}));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        apiRet: { errors: [] },
      });
    }
  },
);

const initState = {
  lists: [],
  isFetching: false,
}


// Slice
const AttrSlice = createSlice({
  name: 'attribute',
  initialState: initState,
  reducers: {
    assignState: (state, action) => {
      const { slicekey, value } = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {
    [getAttr.fulfilled]: (state, { payload }) => {
      state.lists = payload.apiRet;
      state.isFetching = false;
    },
    [getAttr.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },
    [getAttr.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { assignState } = AttrSlice.actions;
export default AttrSlice.reducer;



export const updateAttr = async ({ token, Count,Status }) => {
 const body = JSON.stringify({Count:Count,Status:Status});
  const response = await api.post('grading/','updateStatus',body, token);
  if (response.status === 200) {
      let data  =await response.json();
      let _data = await _decodeData(data);
      if(_data.status){
        return true;
      }else{
        return false;
      }

  }else {
    return 401;
  }
}

export const getUser = async ({ token, Count }) => {
  const response = await api.get(`users/id/${Count}`,token);
  let data  = await response.json();
  if (response.status === 200) {
       let _data = await _decodeData(data);
       return {
         status : 200,
         data : _data
       };
  } else if (response.status === 400) {
      return {
        status : 400,
      };
  } else {
    return {
      status : 401,
    };
  }
}
