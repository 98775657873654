import {createSlice} from '@reduxjs/toolkit';

// Slice
const TheSidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    // sidebarShow : false
    sidebarShow : 'responsive'
  },
  reducers: {
    assignState: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {
  
  },
});

export const {assignState} = TheSidebarSlice.actions;
export default TheSidebarSlice.reducer;
