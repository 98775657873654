import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

// Slice
const HomeSlice = createSlice({
  name: 'homeslice',
  initialState : {
    newMsgCount : 0
  },
  reducers: {
    assignValue: (state, action) => {
      const {slicekey, value} = action.payload;
      state[slicekey] = value;
    },
  },
  extraReducers: {


  },
});

export const {assignValue} = HomeSlice.actions;
export default HomeSlice.reducer;